import Image, { ImageProps } from "next/image"
import React from "react"
import styled from "styled-components"

import { h2, h6 } from "base_css/mixins"
import { rems } from "base_css/utils/fontSize"
import { breakpoint } from "base_css/vars"
import Card from "components/atoms/Card"
import { Cell, Container, Grid } from "components/atoms/Grid"

const SContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.medium};
`

const SCard = styled(Card)`
  padding: ${({ theme }) => `${theme.spacing.large} 0`};
`

const SFeaturedHeader = styled.h2`
  ${h2} /* stylelint-disable-next-line */
  color: ${({ theme }) => theme.color.accent};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: center;

  ${breakpoint.small} {
    margin-left: ${({ theme }) => theme.spacing.small};
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`

const SFeaturedCell = styled(Cell)`
  align-items: center;
  display: flex;
`

interface IImageWrapper {
  width?: string
}

const SImageWrapper = styled.div<IImageWrapper>`
  display: block;
  width: ${({ width }) => width ?? rems(200)};

  ${breakpoint.small} {
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
`

interface IFeaturedImage {
  maxWidth?: string
}

export const SFeaturedImage = ({
  maxWidth,
  ...props
}: ImageProps & IFeaturedImage) => (
  <SImageWrapper width={maxWidth}>
    <Image {...props} />
  </SImageWrapper>
)

export const SFeaturedBodyHeader = styled.h2`
  ${h6} /* stylelint-disable-next-line */
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

export const SFeaturedBodyText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
`

interface IFeatured {
  heading: string
  image: JSX.Element
  body: JSX.Element
  buttons: JSX.Element[]
}

const Featured = ({ heading, image, body, buttons }: IFeatured) => (
  <SContainer>
    <SCard>
      <SFeaturedHeader>{heading}</SFeaturedHeader>
      <Grid rowGap={rems(2)}>
        <SFeaturedCell
          width={12}
          mdWidth={5}
          mdLeft={2}
          lgWidth={5}
          lgLeft={2}
          middle={true}
        >
          {image}
        </SFeaturedCell>
        <SFeaturedCell
          width={10}
          left={2}
          mdWidth={5}
          mdLeft={7}
          lgWidth={5}
          lgLeft={7}
          middle={true}
        >
          <div>
            {body}
            {buttons}
          </div>
        </SFeaturedCell>
      </Grid>
    </SCard>
  </SContainer>
)

export default Featured
