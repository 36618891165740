import React, { useContext } from "react"

import Hero from "components/molecules/Hero"
import HowItWorks from "components/molecules/HowItWorks"
import PageWrapper from "components/molecules/PageWrapper"
import ReviewSection from "components/molecules/ReviewSection"
import {
  SFeatureContainer,
  SReviewSection,
} from "components/organisms/BuyerType"
import FeaturedSpecialist from "components/organisms/FeaturedSpecialist"
import GetStartedSection from "components/organisms/GetStartedSection"
import InlineCalculatorSection from "components/organisms/InlineCalculatorSection"

import { CompanyContext } from "companyContext"
import BorrowCalculator from "components/molecules/BorrowCalculator"

const Home = () => {
  const isCompanyUser = useContext(CompanyContext).companyName !== undefined

  return (
    <PageWrapper title="Expert mortgage advice" titlePosition="end">
      <Hero />
      {isCompanyUser ? (
        <>
          <SFeatureContainer>
            <FeaturedSpecialist />
          </SFeatureContainer>
          <SReviewSection isDark={true} />
        </>
      ) : (
        <ReviewSection isDark={true} />
      )}
      <HowItWorks />
      <GetStartedSection isDark={false} />
      <InlineCalculatorSection>
        <BorrowCalculator orientation="horizontal" />
      </InlineCalculatorSection>
    </PageWrapper>
  )
}

export default Home
