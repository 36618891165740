import React from "react"

import GetStartedButton from "components/molecules/GetStartedButton"
import Featured, {
  SFeaturedBodyHeader,
  SFeaturedBodyText,
  SFeaturedImage,
} from "components/templates/Featured"
import SpecialistImage from "public/images/featured-specialist.png"

const FeaturedSpecialist = () => (
  <Featured
    heading="Meet your dedicated mortgage specialist"
    image={
      <SFeaturedImage
        src={SpecialistImage}
        alt="Ben Kehoe"
        placeholder="blur"
      />
    }
    body={
      <>
        <SFeaturedBodyHeader>Ben Kehoe</SFeaturedBodyHeader>
        <SFeaturedBodyText>
          Ben has 13+ years&apos; experience in the financial services industry,
          working in the UK and Australia. Originally qualifying as a financial
          planner in both countries, Ben&apos;s specialised in helping people
          with mortgages since 2016.
        </SFeaturedBodyText>
      </>
    }
    buttons={[
      <GetStartedButton
        key="specialist"
        small
        analyticsID="Featured GetStartedButton"
      />,
    ]}
  />
)

export default FeaturedSpecialist
