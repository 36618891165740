import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface ICardStyle {
  className?: string
  size?: "large"
}

interface ICard extends ICardStyle {
  children: React.ReactNode
}

const SCard = styled.div<ICardStyle>`
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 ${rems(5)} ${rems(20)} 0 rgba(34, 34, 34, 0.15);
  padding: ${({ size, theme }) =>
    size === "large" ? theme.spacing.large : theme.spacing.medium};

  & > *:last-child {
    margin-bottom: 0;
  }
`

const Card = ({ children, className, size }: ICard) => (
  <SCard className={className} size={size}>
    {children}
  </SCard>
)

export default Card
